<template>
  <div class="healthServices">
    <div class="tab">
      <span :class="{ span: tabIndex === index }" v-for="(item, index) in tabs" :key="index" @click="tabClick(index)">{{
          item
      }}</span>
      <div class="search" v-if="tabIndex !== 2">
        <el-input placeholder="姓名/身份证号" suffix-icon="el-icon-search">
        </el-input>
      </div>
    </div>
    <div class="scroll">
      <Services v-if="tabIndex === 0"></Services>
      <Data v-else-if="tabIndex === 1"></Data>
      <Register v-else-if="tabIndex === 2"></Register>
    </div>
  </div>
</template>

<script>
import Services from './services/services.vue'
import Data from './data/data.vue'
import Register from './register/register.vue'
export default {
  components: {
    Services,
    Data,
    Register
  },
  data () {
    return {
      tabIndex: 0,
      tabs: Object.freeze(['健康体检', '健康数据', '就医登记'])
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    }
  }
}
</script>

<style lang="less" scoped>
.healthServices {
  width: 100%;
  height: 100%;
  padding: .25rem;
  box-sizing: border-box;
  background-color: #081C38;

  .tab {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: .5rem;
    margin-bottom: .125rem;
    color: #fff;
    font-size: .275rem;

    span {
      width: 1.3125rem;
      height: .475rem;
      line-height: .475rem;
      margin-right: .2rem;
      text-align: center;
      background-color: #D5D5D5;
      cursor: pointer;
    }

    .span {
      background-color: #01EEFD;
    }

    .search {
      position: absolute;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: .5rem;

      .el-input {
        width: 3.075rem;
        height: .475rem;
        margin: 0;
        border-radius: .075rem;
      }

      /deep/.el-input__inner {
        background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
        border: 1px solid #1F72D3;
        color: #fff !important;
        font-size: .225rem;
      }

      /deep/ .el-input__suffix {
        color: #fff;
        font-size: .225rem;
      }
    }
  }

  .scroll {
    width: 100%;
    height: calc(100% - .625rem);
  }
}
</style>
