<template>
  <div class="write">
    <div class="title">
      <span>登记</span>
    </div>
    <div class="content">
      <div @click="closePopup" class="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="box">
        <div class="item">
          <span class="span">老人信息</span>
          <el-input></el-input>
        </div>
        <div class="item">
          <span class="span">就医时间</span>
          <el-date-picker type="date" placeholder="选择日期">
          </el-date-picker>
        </div>
        <div class="item">
          <span class="span">医师</span>
          <el-input></el-input>
        </div>
        <div class="item">
          <span class="span">就医地点</span>
          <el-input></el-input>
        </div>
        <div class="item item1">
          <span class="span">就医地点</span>
          <el-input class="textarea" type="textarea"></el-input>
        </div>
        <div class="item item1">
          <span class="span">医嘱</span>
          <el-input class="textarea" type="textarea"></el-input>
        </div>
      </div>
      <div class="btns">
        <el-button>取消</el-button>
        <el-button class="button">确定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  methods: {
    closePopup () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.write {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  width: 100%;
  height: 100%;
  padding: 0 3.75rem;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, .9);

  .title {
    width: 3.3375rem;
    height: .6625rem;
    padding-top: .0625rem;
    box-sizing: border-box;
    line-height: .6625rem;
    text-align: center;
    background-image: url('../../img/ssjk/title.png');
    background-size: 100% 100%;
    color: #fff;
    font-size: .25rem;
    font-weight: 600;
  }

  .content {
    position: relative;
    width: 100%;
    min-width: 12.6625rem;
    height: 6.825rem;
    margin-top: .125rem;
    padding: .625rem .8125rem;
    background-image: url('../../img/ssjk/background4.png');
    background-size: 100% 100%;
    box-sizing: border-box;

    .close {
      position: absolute;
      top: .275rem;
      right: .3rem;
      color: #fff;
      font-size: .3rem;
      cursor: pointer;
    }

    .box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 80%;
      height: 1.75rem;
    }

    .item {
      display: flex;
      align-items: center;
      margin-top: 20px;
      color: #01EEFD;
      font-size: .225rem;

      .span {
        width: 1rem;
        margin-right: .125rem;
      }

      .el-input, /deep/.el-input__inner {
        width: 2.1rem;
        height: .475rem;
      }

      /deep/.el-input__icon {
        line-height: .475rem;
      }

      .textarea, /deep/.el-textarea__inner {
        width: calc(100% - 1rem);
        height: .8375rem;
        resize: none;
        border: none;
      }
    }

    .item1 {
      width: 100%;
      align-items: flex-start;
    }

    .btns {
      position: absolute;
      left: 50%;
      bottom: .75rem;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 3rem;
      height: .325rem;

      .el-button {
        width: .875rem;
        height: .325rem;
        line-height: .325rem;
        padding: 0;
        text-align: center;
        color: #fff;
        font-size: .175rem;
        background-color: #33465C;
        border-radius: 0;
        border: none;
      }

      .button {
        background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
      }
    }
  }
}
</style>
