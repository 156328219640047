<template>
  <div class="register">
    <div class="register-search">
      <el-input size="small" placeholder="姓名/身份证号" suffix-icon="el-icon-search">
      </el-input>
      <el-button @click="openAddRegister">登记</el-button>
    </div>
    <div class="register-box">
      <div class="register-box-item" v-for="(item, index) in 10" :key="index">
        <span>老人姓名：李明明</span>
        <span>就医时间：2021-12-25</span>
        <span>就医地点：古城营镇卫生院</span>
        <span>1.血压测量及记录 2.根据结果提供综合性健康指导</span>
      </div>
    </div>
    <AddRegister v-if="showAddRegister" @close="closeAddRegister"></AddRegister>
  </div>
</template>

<script>
import AddRegister from './addRegister.vue'
export default {
  components: {
    AddRegister
  },
  data () {
    return {
      showAddRegister: false
    }
  },
  methods: {
    openAddRegister () {
      this.showAddRegister = true
    },
    closeAddRegister () {
      this.showAddRegister = false
    }
  }
}
</script>

<style lang="less" scoped>
.register {
  position: relative;
  width: 100%;
  height: 100%;

  .register-search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 4.2rem;
    height: .475rem;

    .el-input {
      width: 3.075rem;
      height: .475rem;
      margin: 0;
      border-radius: .075rem;
    }

    /deep/.el-input__inner {
      background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
      border: 1px solid #1F72D3;
      color: #fff !important;
      font-size: .225rem;
    }

    /deep/ .el-input__suffix {
      color: #fff;
      font-size: .225rem;
    }

    .el-button {
      width: .75rem;
      height: .375rem;
      line-height: .375rem;
      margin: 0;
      border: 0;
      padding: 0;
      text-align: center;
      border-radius: 0;
      background-color: #D5D5D5;
      color: #fff;
      font-size: .2rem;
    }
  }

  .register-box {
    overflow-y: scroll;
    width: 16.4125rem;
    height: 4.25rem;
    margin-top: 10px;
    background: linear-gradient(50deg, rgba(255, 255, 255, 0.0706) 0%, rgba(1, 114, 198, 0.2118) 100%);
    box-shadow: inset 0px 0px .1625rem 1px rgba(1, 116, 200, 1);
    border: 2px solid #016DBD;
    box-sizing: border-box;
    border-radius: .125rem;

    &::-webkit-scrollbar {
      width: 0;
    }

    .register-box-item {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 15.5125rem;
      height: .475rem;
      margin: 0 auto;
      margin-top: .175rem;
      background: linear-gradient(180deg, #094273 0%, #125F9E 100%);
      border: 1px solid #017CDB;
      box-sizing: border-box;
      border-radius: .125rem;
      color: #01EEFD;
      font-size: .225rem;
    }
  }
}
</style>
