var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"data"},[_vm._m(0),_c('div',{staticClass:"data-box"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"item-title"},[_vm._v("人员信息")]),_c('div',{staticClass:"data-scroll"},_vm._l((10),function(item,index){return _c('div',{key:index,staticClass:"data-item"},[_vm._m(1,true),_c('span',{staticClass:"see"},[_vm._v("查看")])])}),0)]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"data-title"},[_c('span',[_vm._v("监测记录")]),_c('div',{staticClass:"data-info"},[_c('span',[_vm._v("姓名: 张小明")]),_c('span',[_vm._v("性别：男")]),_c('span',[_vm._v("年龄: 83")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"width"},[_c('span',[_vm._v("姓名：张明明")]),_c('span',[_vm._v("监测时间：2022-06-08")]),_c('span',[_vm._v("项目值：2022-06-08")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"item-title"},[_vm._v("体温")]),_c('div',{staticClass:"charts",attrs:{"id":"charts1"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"item-title"},[_vm._v("心率")]),_c('div',{staticClass:"charts",attrs:{"id":"charts2"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"item-title"},[_vm._v("血糖")]),_c('div',{staticClass:"charts",attrs:{"id":"charts3"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"item-title"},[_vm._v("体重")]),_c('div',{staticClass:"charts",attrs:{"id":"charts4"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"item-title"},[_vm._v("血压")]),_c('div',{staticClass:"charts",attrs:{"id":"charts5"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"item-title"},[_vm._v("血氧")]),_c('div',{staticClass:"charts",attrs:{"id":"charts6"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"width"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"item-title"},[_vm._v("身高")]),_c('div',{staticClass:"charts",attrs:{"id":"charts7"}})])])
}]

export { render, staticRenderFns }