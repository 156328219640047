<template>
  <div class="data">
    <div class="data-title">
      <span>监测记录</span>
      <div class="data-info">
        <span>姓名: 张小明</span>
        <span>性别：男</span>
        <span>年龄: 83</span>
      </div>
    </div>
    <div class="data-box">
      <div class="item">
        <div class="item-title">人员信息</div>
        <div class="data-scroll">
          <div class="data-item" v-for="(item, index) in 10" :key="index">
            <div class="width">
              <span>姓名：张明明</span>
              <span>监测时间：2022-06-08</span>
              <span>项目值：2022-06-08</span>
            </div>
            <span class="see">查看</span>
          </div>
        </div>
      </div>

      <div class="item">
        <div class="item-title">体温</div>
        <div class="charts" id="charts1"></div>
      </div>

      <div class="item">
        <div class="item-title">心率</div>
        <div class="charts" id="charts2"></div>
      </div>

      <div class="item">
        <div class="item-title">血糖</div>
        <div class="charts" id="charts3"></div>
      </div>

      <div class="item">
        <div class="item-title">体重</div>
        <div class="charts" id="charts4"></div>
      </div>

      <div class="item">
        <div class="item-title">血压</div>
        <div class="charts" id="charts5"></div>
      </div>

      <div class="item">
        <div class="item-title">血氧</div>
        <div class="charts" id="charts6"></div>
      </div>

      <div class="width">
        <div class="item">
          <div class="item-title">身高</div>
          <div class="charts" id="charts7"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      myChart: null,
      myChart2: null,
      myChart3: null,
      myChart4: null,
      myChart5: null,
      myChart6: null,
      myChart7: null
    }
  },
  mounted () {
    this.canvas()
    this.canvas1()
    this.canvas2()
    this.canvas3()
    this.canvas4()
    this.canvas5()
    this.canvas6()
    window.addEventListener('resize', this.getresize)
  },
  beforeDestroy () {
    // 页面销毁时销毁监听事件
    window.removeEventListener('resize', this.getresize)
  },
  methods: {
    getresize () {
      setTimeout(() => {
        this.myChart.resize()
        this.myChart2.resize()
        this.myChart3.resize()
        this.myChart4.resize()
        this.myChart5.resize()
        this.myChart6.resize()
        this.myChart7.resize()
      }, 100)
    },
    canvas () {
      var chartDom = document.getElementById('charts1')
      this.myChart = this.$echarts.init(chartDom)
      var option = {
        calculable: true,
        legend: {
          //   指示框名字  注意！要和下方series中的name一起改
          data: ['早上体温', '晚上体温'],
          // 指示框位置  距离上下左右多少
          right: '5%',
          // top: '50%',
          textStyle: {
            color: '#BAE0FF' // 字体颜色
          }
        },
        xAxis: [
          {
            type: 'category',
            data: ['12-01', '12-02', '12-03', '12-04', '12-05', '12-06', '12-07'],
            axisLine: { // x轴线的颜色以及宽度
              show: true, // 是否显示X轴
              lineStyle: {
                color: '#ffffff'
              }
            },
            axisLabel: {
              // interval: 0,  //间隔几条显示
              // rotate: 20,  // 切斜度数
              // fontSize: 10,   //字体大小
            },
            axisTick: {
              // 是否显示刻度线
              show: false
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '摄氏度（℃）',
            axisTick: {
              // y轴刻度线
              show: false
            },
            axisLine: {
              show: true, // Y轴
              lineStyle: {
                color: '#ffffff'
              }
            },
            splitLine: { // 表格里面Y轴线条
              show: false,
              lineStyle: {
                type: 'dashed', // 虚线
                color: '#04072F' // 虚线颜色
              }
            }
          }
        ],
        // 整体大小
        grid: {
          left: '3%',
          right: '4%',
          top: '20%',
          bottom: '5%',
          containLabel: true
        },
        series: [
          {
            barWidth: 12, // 柱子宽度
            name: '早上体温',
            type: 'bar',
            data: [
              135.6, 162.2, 32.6, 20.0, 6.4
            ],
            itemStyle: {
              borderRadius: [4, 4, 0, 0], // 圆角[上左，上右，下右，下左]
              label: {
                show: false, // 开启数字显示
                position: 'top', // 在上方显示数字
                color: '#fff', // 字体颜色
                fontSize: 10 // 字体大小
              },
              //   柱状图颜色渐变
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#FA923D' },
                { offset: 1, color: '#905E38' }
              ])
            }

          },
          {
            barWidth: 12, // 柱子宽度
            name: '晚上体温',
            type: 'bar',
            data: [
              28.7, 70.7, 175.6, 182.2, 48.7
            ],
            itemStyle: {
              // 颜色样式部分
              borderRadius: [4, 4, 0, 0], // 圆角[上左，上右，下右，下左]
              label: {
                show: false, // 开启数字显示
                position: 'top', // 在上方显示数字
                color: '#fff', // 字体颜色
                fontSize: 10 // 字体大小
              },
              //   柱状图颜色渐变
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#02E5F5' },
                { offset: 1, color: '#075978' }
              ])
            }
          }
        ]
      }

      option && this.myChart.setOption(option)
    },
    canvas1 () {
      var chartDom = document.getElementById('charts2')
      this.myChart2 = this.$echarts.init(chartDom)
      var option = {
        calculable: true,
        legend: {
          //   指示框名字  注意！要和下方series中的name一起改
          data: ['心率'],
          // 指示框位置  距离上下左右多少
          right: '5%',
          // top: '50%',
          textStyle: {
            color: '#BAE0FF' // 字体颜色
          }
        },
        xAxis: [
          {
            type: 'category',
            data: ['12-01', '12-02', '12-03', '12-04', '12-05', '12-06', '12-07'],
            axisLine: { // x轴线的颜色以及宽度
              show: true, // 是否显示X轴
              lineStyle: {
                color: '#ffffff'
              }
            },
            axisLabel: {
              // interval: 0,  //间隔几条显示
              // rotate: 20,  // 切斜度数
              // fontSize: 10,   //字体大小
            },
            axisTick: {
              // 是否显示刻度线
              show: false
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '次/分',
            axisTick: {
              // y轴刻度线
              show: false
            },
            axisLine: {
              show: true, // Y轴
              lineStyle: {
                color: '#ffffff'
              }
            },
            splitLine: { // 表格里面Y轴线条
              show: false,
              lineStyle: {
                type: 'dashed', // 虚线
                color: '#04072F' // 虚线颜色
              }
            }
          }
        ],
        // 整体大小
        grid: {
          left: '3%',
          right: '4%',
          top: '20%',
          bottom: '5%',
          containLabel: true
        },
        series: [
          {
            barWidth: 12, // 柱子宽度
            name: '心率',
            type: 'bar',
            data: [
              135.6, 162.2, 32.6, 20.0, 6.4
            ],
            borderRadius: [4, 4, 0, 0], // 圆角[上左，上右，下右，下左]
            label: {
              show: false, // 开启数字显示
              position: 'top', // 在上方显示数字
              color: '#fff', // 字体颜色
              fontSize: 10 // 字体大小
            },
            //   柱状图颜色渐变
            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: '#00F0A7' },
              { offset: 1, color: '#0064C2' }
            ])

          }
        ]
      }

      option && this.myChart2.setOption(option)
    },
    canvas2 () {
      var chartDom = document.getElementById('charts3')
      this.myChart3 = this.$echarts.init(chartDom)
      var option = {
        calculable: true,
        legend: {
          //   指示框名字  注意！要和下方series中的name一起改
          data: ['血糖'],
          // 指示框位置  距离上下左右多少
          right: '5%',
          // top: '50%',
          textStyle: {
            color: '#BAE0FF' // 字体颜色
          }
        },
        xAxis: [
          {
            type: 'category',
            data: ['12-01', '12-02', '12-03', '12-04', '12-05', '12-06', '12-07'],
            axisLine: { // x轴线的颜色以及宽度
              show: true, // 是否显示X轴
              lineStyle: {
                color: '#ffffff'
              }
            },
            axisLabel: {
              // interval: 0,  //间隔几条显示
              // rotate: 20,  // 切斜度数
              // fontSize: 10,   //字体大小
            },
            axisTick: {
              // 是否显示刻度线
              show: false
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '次/分',
            axisTick: {
              // y轴刻度线
              show: false
            },
            axisLine: {
              show: true, // Y轴
              lineStyle: {
                color: '#ffffff'
              }
            },
            splitLine: { // 表格里面Y轴线条
              show: false,
              lineStyle: {
                type: 'dashed', // 虚线
                color: '#04072F' // 虚线颜色
              }
            }
          }
        ],
        // 整体大小
        grid: {
          left: '3%',
          right: '4%',
          top: '16%',
          bottom: '5%',
          containLabel: true
        },
        series: [
          // 表格中展示颜色的配置项
          {
            name: '血糖',
            type: 'bar',
            barWidth: '18', // 柱体宽度
            itemStyle: {
              // 颜色样式部分
              borderRadius: [10, 10, 0, 0], // 圆角[上左，上右，下右，下左]
              label: {
                formatter (params) {
                  for (let i = 0; i < 6; i++) {
                    if (params.data.Index === i) {
                      return [135.6, 162.2, 32.6, 20.0, 6.4][i]
                    }
                  }
                },
                show: true, // 开启数字显示
                position: 'top', // 在上方显示数字
                color: '#fff', // 字体颜色
                fontSize: 10 // 字体大小
              },
              //   柱状图颜色渐变
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#00F0A7' },
                { offset: 1, color: '#0064C2' }
              ]),
              silent: true,
              barGap: '-100%',
              data: [10, 10, 10]
            },
            zlevel: 3,
            data: [135.6, 162.2, 32.6, 20.0, 6.4], // 数据源
            label: {
              show: true,
              color: '#fff',
              fontSize: 12,
              position: 'top'
            },
            showBackground: true,
            backgroundStyle: {
              color: '#324F6A',
              borderColor: '#324F6A',
              borderWidth: 3,
              borderRadius: 10
            }
          },
          // 表格中每条数据的背景阴影的配置项
          {
            name: '',
            type: 'bar',
            barWidth: '18', // 背景阴影宽度
            barGap: '-100%', // 进行偏移，将背景移到数据正下方
            itemStyle: {
              color: 'rgba(255,255,255,0.1)'
            },
            zlevel: 2,
            data: ['10', '10', '10', '10', '10'], // 每项阴影显示高度
            label: {
              show: false,
              color: '#fff',
              fontSize: 12,
              position: 'top'
            }
          }
        ]
      }
      option && this.myChart3.setOption(option)
    },
    canvas3 () {
      var chartDom = document.getElementById('charts4')
      this.myChart4 = this.$echarts.init(chartDom)
      var option = {
        legend: {
          //   指示框名字  注意！要和下方series中的name一起改
          data: ['体重'],
          // 指示框位置  距离上下左右多少
          right: '5%',
          // top: '50%',
          textStyle: {
            color: '#BAE0FF' // 字体颜色
          }
        },
        xAxis: {
          type: 'category',
          data: ['1/4', '1/24', '1/31', '2/4', '2/14', '2/18', '3/1'],
          axisLabel: {
            show: true,
            color: '#fff',
            fontSize: 15
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: ['#01EEFD']
            }
          },
          axisTick: {
            // y轴刻度线
            show: false
          }
        },
        yAxis: {
          type: 'value',
          name: 'kg',
          nameTextStyle: {
            color: '#fff',
            nameLocation: 'start'
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: ['#fff']
            }
          },
          axisLabel: {
            show: true,
            color: '#fff',
            fontSize: 15
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          top: '16%',
          bottom: '5%',
          containLabel: true
        },
        series: [
          {
            name: '体重',
            data: [150, 230, 224, 218, 135, 147, 260],
            type: 'line',
            SymbolSize: 30,
            itemStyle: {
              color: '#fff' // 改变折线点的颜色
            },
            lineStyle: {
              color: '#03BDD2' // 改变折线颜色
            }
          }
        ]
      }
      option && this.myChart4.setOption(option)
    },
    canvas4 () {
      var chartDom = document.getElementById('charts5')
      this.myChart5 = this.$echarts.init(chartDom)
      var option = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          //   指示框名字  注意！要和下方series中的name一起改
          data: ['收缩压', '舒张压'],
          // 指示框位置  距离上下左右多少
          right: '5%',
          // top: '50%',
          textStyle: {
            color: '#BAE0FF' // 字体颜色
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['1月', '1月', '1月', '1月', '1月', '1月', '1月'],
          axisLabel: {
            show: true,
            color: '#fff',
            fontSize: 15
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: ['#01EEFD']
            }
          },
          axisTick: {
            // y轴刻度线
            show: false
          }
        },
        yAxis: {
          type: 'value',
          name: 'mmHg',
          nameTextStyle: {
            color: '#fff',
            nameLocation: 'start'
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: ['#fff']
            }
          },
          axisLabel: {
            show: true,
            color: '#fff',
            fontSize: 15
          }
        },
        series: [
          {
            name: '收缩压',
            type: 'line',
            stack: 'Total',
            data: [120, 132, 101, 134, 90, 230, 210],
            itemStyle: {
              color: '#fff' // 改变折线点的颜色
            },
            lineStyle: {
              color: '#03BDD2' // 改变折线颜色
            }
          },
          {
            name: '舒张压',
            type: 'line',
            stack: 'Total',
            data: [220, 182, 191, 234, 290, 330, 310],
            itemStyle: {
              color: '#fff' // 改变折线点的颜色
            },
            lineStyle: {
              color: '#EE8B3B' // 改变折线颜色
            }
          }
        ]
      }
      option && this.myChart5.setOption(option)
    },
    canvas5 () {
      var chartDom = document.getElementById('charts6')
      this.myChart6 = this.$echarts.init(chartDom)
      var option = {
        grid: {
          left: '3%',
          right: '4%',
          top: '3%',
          bottom: '5%',
          containLabel: true
        },
        series: [
          {
            type: 'gauge',
            axisLine: {
              lineStyle: {
                width: 20,
                color: [
                  [0.3, '#67e0e3'],
                  [0.7, '#37a2da'],
                  [1, '#fd666d']
                ]
              }
            },
            pointer: {
              itemStyle: {
                color: 'inherit'
              }
            },
            splitLine: {
              distance: -30,
              length: 30,
              lineStyle: {
                color: 'rgba(0,0,0,0)',
                width: 4
              }
            },
            axisLabel: {
              color: 'inherit',
              distance: 40,
              fontSize: 10
            },
            detail: {
              valueAnimation: true,
              formatter: '{value} %',
              color: '#EE8B3B',
              fontSize: 13
            },
            data: [
              {
                value: 70,
                color: '#fff'
              }
            ]
          }
        ]
      }
      option && this.myChart6.setOption(option)
    },
    canvas6 () {
      var chartDom = document.getElementById('charts7')
      this.myChart7 = this.$echarts.init(chartDom)
      var option = {
        legend: {
          //   指示框名字  注意！要和下方series中的name一起改
          data: ['身高'],
          // 指示框位置  距离上下左右多少
          right: '5%',
          // top: '50%',
          textStyle: {
            color: '#BAE0FF' // 字体颜色
          }
        },
        xAxis: {
          type: 'category',
          data: ['1/4', '1/24', '1/31', '2/4', '2/14', '2/18', '3/1'],
          axisLabel: {
            show: true,
            color: '#fff',
            fontSize: 15
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: ['#01EEFD']
            }
          },
          axisTick: {
            // y轴刻度线
            show: false
          }
        },
        yAxis: {
          type: 'value',
          name: 'cm',
          nameTextStyle: {
            color: '#fff',
            nameLocation: 'start'
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: ['#fff']
            }
          },
          axisLabel: {
            show: true,
            color: '#fff',
            fontSize: 15
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          top: '20%',
          bottom: '5%',
          containLabel: true
        },
        series: [
          {
            name: '身高',
            data: [150, 230, 224, 218, 135, 147, 260],
            type: 'line',
            SymbolSize: 30,
            itemStyle: {
              color: '#fff' // 改变折线点的颜色
            },
            lineStyle: {
              color: '#03BDD2' // 改变折线颜色
            }
          }
        ]
      }
      option && this.myChart7.setOption(option)
    }
  }
}
</script>

<style lang="less" scoped>
.data {
  width: 100%;
  height: 100%;

  .data-title {
    display: flex;
    align-items: center;
    height: .45rem;
    color: #01EEFD;
    font-size: .275rem;

    span {
      height: .45rem;
      line-height: .45rem;
    }

    .data-info {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 5.675rem;
      height: .45rem;
      margin-left: .3625rem;
      color: #fff;
      background: linear-gradient(-10deg, rgba(1, 109, 189, 0) 0%, rgba(4, 110, 193, .67) 100%);
      border-radius: .125rem .125rem .125rem .125rem;
    }
  }

  .data-box {
    overflow-y: scroll;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    height: calc(100% - .575rem);
    margin-top: .125rem;

    &::-webkit-scrollbar {
      width: 0;
    }

    .width {
      width: 66%;
    }

    .item {
      min-width: 560px;
      min-height: 340px;
      width: 7rem;
      height: 4.25rem;
      margin-bottom: 20px;
      padding: .25rem;
      box-sizing: border-box;
      background-image: url('../../img/ssjk/background7.png');
      background-size: 100% 100%;

      .item-title {
        width: 2.4rem;
        height: .4375rem;
        line-height: .525rem;
        padding-left: .3rem;
        box-sizing: border-box;
        background-image: url('../../img/ssjk/title.png');
        background-size: 100% 100%;
        color: #fff;
        font-size: .25rem;
        font-weight: bold;
      }

      .data-scroll {
        overflow-y: scroll;
        width: 100%;
        height: calc(100% - .575rem);
        margin-top: .125rem;

        &::-webkit-scrollbar {
          width: 0;
        }

        .data-item {
          display: flex;
          align-items: center;
          justify-content: space-around;
          width: 100%;
          height: .525rem;
          margin-bottom: .125rem;
          background: linear-gradient(180deg, #094273 0%, #125F9E 100%);
          border: 1px solid #017CDB;
          box-sizing: border-box;
          border-radius: .075rem;
          color: #01EEFD;
          font-size: .15rem;

          .width {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: calc(100% - 100px);
          }

          .see {
            cursor: pointer;
          }
        }
      }

      .charts {
        width: 100%;
        height: calc(100% - .575rem);
        margin-top: .125rem;
      }
    }
  }
}
</style>
